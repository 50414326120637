import { IAutoCompleteItem } from "./autoComplete";
export interface ICell {
    id: string | number,
    title: string;
    height: string;
    width: string;
    cellSize: number | string;
    isActive: boolean
}
export interface ICellUpdate {
    title: string;
    isActive: boolean
}
export interface ICellManagement {
    cellData: ICell;
    cellsLists:ICell[]|[],
    cellSizesList:IAutoCompleteItem[] | [],
    setNewCellId:(value:number) => void
}

export const enum CellStatus {
    Faulty = 8001,
    Blocked = 8002,
    Occupied = 8003,
    Available = 8004
}

export const cellSizeText: any = {
    17001: 'S',
    17002: 'M',
    17003: 'L',
    17004: 'XL',
    17005: 'XXL'
}

export enum CellSize {
    Small = 17001,
    Medium = 17002,
    Large = 17003,
    XLarge = 17004,
    XXLarge = 17005,
}
